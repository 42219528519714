import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
  callStarted: false,
  partnerTypeOffer: '',
  localTypeOffer: '',
  isRinging: false,
  isUserBusy: false,
  partnerSocketID: '',
  typeOfCall: '',
  offer: null,
  payWarning: false,
}

export const callSlice = createSlice({
  name: 'call',
  initialState,
  reducers: {
    onOutcomingCall: (state, action) => {
      state.callStarted = true
      state.localTypeOffer = action.payload
    },
    onStopCalling: (state, action) => {
      state.callStarted = false
      state.localTypeOffer = ''
    },
    onIncomingCall: (state, action) => {
      state.callStarted = true
      state.isRinging = false
    },
    onStartRinging: (state, action) => {
      state.isRinging = true
      state.partnerTypeOffer = action.payload.type
      state.localTypeOffer = action.payload.type
      state.partnerSocketID = action.payload.target
    },
    onStopRinging: (state, action) => {
      state.isRinging = false
      state.partnerTypeOffer = ''
      state.partnerSocketID = ''
    },
    onUserBusy: (state, action) => {
      state.callStarted = false
      state.localTypeOffer = ''
    },
    onAcceptCall: (state, action) => {
      state.partnerTypeOffer = action.payload.type
      state.partnerSocketID = action.payload.target
      state.typeOfCall = 'caller'
    },

    onAnotherDevice: (state, action) => {
      state.isRinging = false
      state.partnerTypeOffer = null
      state.localTypeOffer = null
      state.partnerSocketID = null
    },
    onOffer: (state, action) => {
      state.offer = action.payload
    },

    onPayWarning: (state, action) => {
      state.payWarning = action.payload
    },

    endCallAction: (state, action) => {
      state.callStarted = false
      state.partnerTypeOffer = ''
      state.localTypeOffer = ''
      state.isRinging = false
      state.isAcceptedOnAnotherDevice = false
      state.isUserBusy = false
      state.partnerSocketID = ''
      state.typeOfCall = ''
      state.offer = null
    },
  },
})

export const {
  onOutcomingCall,
  onIncomingCall,
  onStartRinging,
  onAcceptCall,
  onOffer,
  endCallAction,
  onPayWarning,
  onUserBusy,
  onStopCalling,
  onStopRinging,
  onAnotherDevice,
} = callSlice.actions
export default callSlice.reducer

export const selectLayouts = (state) => state.call
