import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Lock from '../../assets/images/lock.png'
import {
  changeStep,
  selectRegister,
  updateCard,
} from '../../redux/registerSlice'

const CardStep = () => {
  const dispatch = useDispatch()

  const { cardDetails, packages, clientDetails } = useSelector(selectRegister)
  // Card
  const [cardNum, setCardNum] = useState(
    cardDetails?.number ? cardDetails.number : ''
  )
  const [cardNumError, setCardNumError] = useState(false)

  const [cvc, setCvc] = useState('')
  const [cvcError, setCvcError] = useState(false)

  const [month, setMonth] = useState('')
  const [monthError, setMonthError] = useState(false)

  const [year, setYear] = useState('')
  const [yearError, setYearError] = useState(false)

  // Card
  const handleChange = (event) => {
    const rawValue = event.target.value
    let formattedValue = ''

    if (rawValue) {
      formattedValue = rawValue
        .replace(/[^0-9]/gi, '')
        .match(/.{1,4}/g)
        .join('-')
    }

    setCardNum(formattedValue)
  }

  function luhn(cnnr) {
    var d,
      i,
      total = 0

    //Take each digit of cnnr. Start with LSD, go towards MSD
    for (i = 1; i <= cnnr.length; ++i) {
      d = +cnnr.charAt(cnnr.length - i)

      if (isNaN(d)) {
        return false //non-numeric data can't pass
      }

      if (i % 2 === 1) {
        //odd digits are just added
        total += d
        continue
      }

      //even digits are doubled
      var lValue = 2 * d

      //if value is > 9 its digits are summed
      if (lValue > 9) {
        //(for 2 digits number -= 9 works as digits sum)
        lValue -= 9
      }

      total += lValue
    }

    return total % 10 === 0 // check if total is divisible by 10
  }

  function validateCNNR(card) {
    const cnnr = card.replaceAll('-', '')
    var $BANK_CCNR_REGEXP = new RegExp(
      [
        '^(4\\d{11}|', //VISA
        '5[1-5]\\d{10}|', //MC
        '6(011|5[0-9]{2})\\d{8}|', //Discover Card
        '3[47]\\d{9}|', //American Express
        '3(0[0-5]|[68]\\d)\\d{7}|', //Diners Club (CarteBlanche)
        '(2131|1800|35\\d{3})\\d{7})', //JCB
        '(\\d{4})$',
      ].join('')
    )

    if ($BANK_CCNR_REGEXP.test(cnnr)) {
      return luhn(cnnr)
    } else {
      return false
    }
  }

  const onCardStep = () => {
    setCardNumError(cardNum === '' || !validateCNNR(cardNum) ? true : false)
    setCvcError(cvc === '' ? true : false)
    setYearError(year === '' ? true : false)
    setMonthError(month === '' ? true : false)

    if (
      cardNum === '' ||
      !validateCNNR(cardNum) ||
      cvc === '' ||
      year === '' ||
      month === ''
    )
      return

    dispatch(
      updateCard({
        cardNumber: cardNum,
        cvc,
        expYear: year.slice(-2),
        expMonth: ('0' + month).slice(-2),
      })
    )

    if (clientDetails) {
      dispatch(changeStep('paying'))
    } else {
      dispatch(changeStep('register'))
    }
  }

  return (
    <>
      <div className="payment__info">
        <h2>Fill your information</h2>
        <p>
          To proceed with your call, please fill checkout form. Your email is
          going to be used for receipts. No spam ever!
        </p>
      </div>
      <div className="card">
        <div className="card__package">
          <div className="pack">
            <p>Price of package</p>
            <p className="price">{packages?.price}€</p>
          </div>
          <div className="pack">
            <p>Package time</p>
            <p className="time">{packages?.min} minutes</p>
          </div>
        </div>

        <div className="card__form">
          <label>
            Credit card number <span>*</span>
          </label>
          <div className={`input ${cardNumError && 'error'}`}>
            <input
              value={cardNum}
              onChange={handleChange}
              type="text"
              placeholder="XXXX - XXXX - XXXX - XXXX"
            />
          </div>
        </div>
        <div className="card__form">
          <label>
            CVC <span>*</span>
          </label>
          <div className={`input ${cvcError && 'error'}`}>
            <input
              value={cvc}
              onChange={(e) => setCvc(e.target.value)}
              type="text"
              placeholder="Example. 123"
            />
          </div>
        </div>
        <div className="card__form">
          <label>
            Expire date (MONTH/YEAR)<span>*</span>
          </label>
          <div className="date">
            <div className={`select__wraper ${yearError && 'error'}`}>
              <select
                value={year}
                onChange={(e) => setYear(e.target.value)}
                placeholder="Year"
                id="year"
              >
                <option value="">Year</option>
                {Array.from(
                  { length: 11 },
                  (_, index) => new Date().getFullYear() + index
                ).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
            <div className={`select__wraper ${monthError && 'error'}`}>
              <select
                value={month}
                onChange={(e) => setMonth(e.target.value)}
                placeholder="Month"
                id="month"
              >
                <option value="">Month</option>
                {Array.from({ length: 12 }, (_, index) => index + 1).map(
                  (monthNum) => (
                    <option key={monthNum} value={monthNum}>
                      {monthNum}
                    </option>
                  )
                )}
              </select>
            </div>
          </div>
        </div>

        <p className="lock">
          <img src={Lock} alt="lock" /> Your payment info will be stored
          securely.
        </p>
      </div>

      <div className="payment__btns">
        <button
          onClick={() => dispatch(changeStep('packages'))}
          className="back"
        >
          Back
        </button>
        <button onClick={() => onCardStep()} className="next">
          Proceed to Call
        </button>
      </div>
    </>
  )
}

export default CardStep
