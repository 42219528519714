import React, { useEffect, useState } from 'react'

import { useGoogleLogin } from '@react-oauth/google'
import googleLogo from '../../assets/images/google_logo.png'
import axios from 'axios'
import { Loader } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeStep,
  selectRegister,
  updateClient,
} from '../../redux/registerSlice'

const RegisterStep = ({ setPaymentDetails, setStep }) => {
  const { step } = useSelector(selectRegister)
  const dispatch = useDispatch()

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)

  const [loading, setLoading] = useState(false)

  // Login
  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const { data } = await axios.get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
            Accept: 'application/json',
          },
        }
      )
      console.log(step)
      dispatch(updateClient({ google_reg: '1', ...data }))

      dispatch(changeStep('paying'))
    },
    onError: (error) => console.error('Login failed:', error),
  })

  const isValidEmail = (email) => {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const checkMail = async (email) => {
    setLoading(true)
    try {
      const { data } = await axios(
        'https://validatemy.email/validate.php?s=xnMvus2zxvPgSG2YRQmwa3sK&e=' +
          email
      )

      setLoading(false)
      if (data.email === 'valid') {
        setEmailError(false)
        return true
      } else {
        setEmailError(true)
        return false
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onPayment = async () => {
    if (!isValidEmail(email)) {
      setEmailError(true)
      return
    }

    if (await checkMail(email)) {
      dispatch(updateClient({ google_reg: '0', email }))

      dispatch(changeStep('paying'))
    }
  }

  return (
    <>
      {loading && (
        <div className="loading loading-email">
          <div className="loader">
            <Loader color="#B4B7BD" size="64" />
          </div>
        </div>
      )}
      <div className="payment__info">
        <h2>Complete Your Details</h2>
        <p>
          To proceed with the call, please enter your email address or use the
          Google button to sign in.
        </p>
        <br></br>
        <p>
          This will help us connect you with the right person and ensure a
          seamless experience.
        </p>
      </div>
      <div className="auth">
        <div className="card__form">
          <label>
            Email <span>*</span>
          </label>
          <div className={`input ${emailError && 'error'}`}>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Email"
            />
          </div>
        </div>

        <div className="or">Or</div>

        <button className="google_btn" onClick={googleLogin}>
          <img src={googleLogo} alt="googleLogo" />
          Sign in with Google
        </button>
      </div>
      <div className="payment__btns">
        <button onClick={onPayment} className="next">
          Proceed to Call
        </button>
      </div>
    </>
  )
}

export default RegisterStep
