import React, { useEffect, useState } from 'react'
import { Loader } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import redLock from '../../assets/images/lock-red.png'
import { loginClient } from '../../redux/authSlice'
import { selectRegister } from '../../redux/registerSlice'

const ExistingStep = () => {
  const { topicId } = useParams()
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(false)

  const [resetPass, setResetPass] = useState(false)

  const dispatch = useDispatch()
  const { isLoading, error } = useSelector((state) => state.auth)
  const { clientDetails } = useSelector(selectRegister)

  const onResetPass = () => {
    console.log('Reset password handler')
    setOpenModal(false)
  }

  const onSubmit = async () => {
    setPasswordError(password.trim() === '' ? true : false)
    if (password.trim() === '') return

    dispatch(
      loginClient({
        ...clientDetails,
        password,
        topicId,
      })
    )
  }

  return (
    <>
      {isLoading && (
        <div className="loading loading-email">
          <div className="loader">
            <Loader color="#B4B7BD" size="64" />
          </div>
        </div>
      )}
      {resetPass && (
        <div className="modal">
          <div className="inner">
            <div className="content">
              <div className="reset-pass">
                <img src={redLock} alt="redLock" />
                <h3>Password reset?</h3>
                <p>
                  If you have forgotten your password, don't worry! You can
                  easily reset it and we'll send instructions to your email.
                </p>
              </div>

              <div className="content__btns">
                <button onClick={() => setOpenModal(false)} className="cancel">
                  Cancel
                </button>
                <button onClick={onResetPass} className="success">
                  Reset password
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="payment__info">
        <h2>Existing User</h2>
        <p>
          It appears that you are an existing user with this email address. To
          proceed with your request, please enter the password associated with
          your account.
        </p>
      </div>
      <div className="auth">
        <div className="card__form">
          <div className="forgot-pass">
            <label>
              Password <span>*</span>
            </label>
            <button onClick={() => setResetPass(true)}>Forgot Password?</button>
          </div>
          <div className={`input ${passwordError && 'error'}`}>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="Password"
            />
          </div>
        </div>
      </div>
      {error && <h2 style={{ color: 'red', textAlign: 'center' }}>{error}</h2>}
      <div className="payment__btns">
        <button onClick={onSubmit} className="next">
          Proceed to Call
        </button>
      </div>
    </>
  )
}

export default ExistingStep
