import React, { useEffect, useState } from 'react'

import '../assets/styles/base.scss'

import WidgetIcon from '../assets/images/WidgetIcon.png'
import Main from '../components/Main'

import { useDispatch, useSelector } from 'react-redux'
import { selectLayouts, toggleWidget } from '../redux/layoutSlice'
import { useParams } from 'react-router'
import { getInfo } from '../redux/infoSlice'

const ClientWidget = () => {
  const dispatch = useDispatch()
  const { openWidget } = useSelector(selectLayouts)

  const { topicId } = useParams()

  useEffect(() => {
    dispatch(getInfo(topicId))
  }, [])

  return (
    <>
      <div className={`widget ${openWidget ? 'open' : 'no-open'}`}>
        {!openWidget && (
          <>
            <button
              className="widget-icon"
              onClick={() => dispatch(toggleWidget(true))}
            >
              <img src={WidgetIcon} alt="Icon" />
            </button>
          </>
        )}

        {openWidget && <Main />}
      </div>
    </>
  )
}

export default ClientWidget
