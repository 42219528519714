import React, { useState } from 'react'
import Star from '../../assets/images/star.png'
import StarFill from '../../assets/images/star-fill.png'
import { logoutClient } from '../../redux/authSlice'
import { selectLayouts, toggleLogout } from '../../redux/layoutSlice'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../Modal'

const CallEndedModal = () => {
  const dispatch = useDispatch()

  // const { openPasswordReset } = useSelector(selectLayouts)

  const openPasswordReset = false

  const [rating, setRating] = useState(0)

  const handleStarClick = (value) => {
    setRating(value)
  }

  const renderStars = () => {
    const stars = []

    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span key={i} className="star" onClick={() => handleStarClick(i)}>
          <img src={i <= rating ? StarFill : Star} alt={`Star ${i}`} />
        </span>
      )
    }

    return stars
  }

  return (
    <Modal
      hide={!openPasswordReset}
      onClose={() => {
        console.log('close')
      }}
    >
      <div className="call-ended-modal">
        <div>
          <h3>Call ended!</h3>
          <p>
            Your feedback is important to us! Please take a moment to rate your
            advisor and your experience.
          </p>
        </div>

        <div className="stars">{renderStars()}</div>

        <div>
          <p>A short review would be greatly appreciated as well.</p>
          <textarea placeholder="Write your review here!"></textarea>
        </div>

        <p>
          Your opinions help us improve and provide better support for you in
          the future.
        </p>

        <div className="btns single">
          <button onClick={() => {}} className="btn-primary">
            Submit review
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default CallEndedModal
