import React, { useEffect } from 'react'
import Alert from '../../assets/images/alert.png'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../Modal'
import { backOnPaymentModal, resetPayment } from '../../redux/paymentSlice'
import { useDeleteCardMutation } from '../../redux/widgetApiSlice'

const SureModal = () => {
  const dispatch = useDispatch()

  const { sureModal, deleteCardId } = useSelector((state) => state.payment)

  const [deleteCard, { isSuccess }] = useDeleteCardMutation()

  const handleDeleteCard = () => {
    deleteCard(deleteCardId)
  }

  useEffect(() => {
    if (isSuccess) {
      dispatch(backOnPaymentModal())
    }
  }, [isSuccess])

  return (
    <Modal
      hide={!sureModal}
      disableClose
      onClose={() => dispatch(resetPayment())}
    >
      <div className="simple-modal">
        <div className="simple-modal__content">
          <img src={Alert} alt="LockGreen" />
          <h3>Are you sure?</h3>
          <p>You won’t be able to revert this!</p>
        </div>

        <div className="btns">
          <button onClick={handleDeleteCard} className="btn-primary">
            Yes, delete it!
          </button>
          <button
            onClick={() => dispatch(backOnPaymentModal())}
            className="btn-secondary danger"
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default SureModal
