import NonAuthContent from './NonAuthorized/NonAuthContent'

import { useDispatch, useSelector } from 'react-redux'
import WidgetHeader from './Layout/WidgetHeader'
import { selectLayouts, toggleWidget } from '../redux/layoutSlice'

import { SocketProvider } from '../utility/context/socketContext'
import ExistSession from './ExistSession'

import Loading from '../components/Loading'
import AuthMain from './Authorized/AuthMain'

const Main = () => {
  const dispatch = useDispatch()
  const { isAuth } = useSelector((state) => state.auth)
  const { openLogin, existSession } = useSelector(selectLayouts)
  const { isLoading, isSuccess, data } = useSelector((state) => state.info)

  const isActive = data?.isActive

  if (!isSuccess) {
    return (
      <>
        <div className="widget-container">
          <div className="no-active-widget">
            <h2>This widget is currently inactive at the moment.</h2>
            <button
              className="video"
              onClick={() => dispatch(toggleWidget(false))}
            >
              Close
            </button>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="widget-container">
        {!isActive && (
          <div className="no-active-widget">
            <h2>This widget is currently inactive at the moment.</h2>
            <button
              className="video"
              onClick={() => dispatch(toggleWidget(false))}
            >
              Close
            </button>
          </div>
        )}
        {isLoading && <Loading />}
        <WidgetHeader />

        {existSession && <ExistSession />}

        {isAuth && isActive && (
          <SocketProvider>
            <>
              <AuthMain />
            </>
          </SocketProvider>
        )}

        {!isActive ? <NonAuthContent /> : !isAuth ? <NonAuthContent /> : null}
      </div>
    </>
  )
}

export default Main
