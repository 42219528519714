import React from 'react'

import AuthContent from './AuthContent'

import VideoCall from './VideoCall'
import PaymentModal from '../Modals/PaymentModal'
import LogoutModal from '../Modals/LogoutModal'
import SureModal from '../Modals/SureModal'
import WaitModal from '../Modals/WaitModal'
import NewCardModal from '../Modals/NewCardModal'
import CallEndedModal from '../Modals/CallEndedModal'
import MinutesAlertModal from '../Modals/MinutesAlertModal'
import { useSelector } from 'react-redux'

const AuthMain = () => {
  const { paymentModal, waitModal } = useSelector((state) => state.payment)

  return (
    <>
      <MinutesAlertModal />
      <CallEndedModal />
      <NewCardModal />
      {paymentModal && <PaymentModal />}
      {waitModal && <WaitModal />}
      <SureModal />
      <LogoutModal />
      <AuthContent />
      <VideoCall />
    </>
  )
}

export default AuthMain
