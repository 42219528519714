import React from 'react'
import InfoWrap from '../../assets/images/info-wrap.png'
import { logoutClient } from '../../redux/authSlice'
import { selectLayouts, toggleLogout } from '../../redux/layoutSlice'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../Modal'
import { resetPayment } from '../../redux/paymentSlice'

const MinutesAlertModal = () => {
  const dispatch = useDispatch()

  const { minutesAlertModal } = useSelector((state) => state.payment)

  return (
    <Modal hide={!minutesAlertModal} onClose={() => dispatch(resetPayment())}>
      <div className="minutes-alert-modal">
        <div className="minutes-alert-modal__content">
          <div>
            <h3>Heads up!</h3>
            <p>
              You have <span>12:00 minutes left</span>!
            </p>
            <p>Make sure you top up your minutes before you proceed.</p>
            <button className="btn-primary">Get more minutes</button>
          </div>
          <img src={InfoWrap} alt="InfoWrap" />
        </div>

        <div className="btns single">
          <button className="btn-secondary">Proceed with Call</button>
        </div>
      </div>
    </Modal>
  )
}

export default MinutesAlertModal
