import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosAuth } from '../utility/api/authAxios'

export const getAllMessages = createAsyncThunk(
  'chat/getAllMessages',
  async (id, thunkAPI) => {
    try {
      const response = await axiosAuth(thunkAPI).get(
        `/messages/client/all/${id}`
      )

      return response.data
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const sendMessage = createAsyncThunk(
  'chat/sendMessage',
  async ({ message, topicId }, thunkAPI) => {
    try {
      const response = await axiosAuth(thunkAPI).post('/messages/widget/send', {
        topicId,
        message,
      })

      return response.data
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

const initialState = {
  id: null,
  messages: null,
  isSuccess: false,
  isLoading: false,
  isError: false,
  error: '',
}

export const ChatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    addMessage: (state, action) => {
      state.messages.push(action.payload)
    },
    removeLastMessage: (state, action) => {
      state.messages.pop()
      state.messages.push(action.payload)
    },
  },
  extraReducers: (builder) => {
    builder
      // Register
      .addCase(getAllMessages.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllMessages.fulfilled, (state, action) => {
        state.messages = action.payload.data.messages
        state.id = action.payload.data.id
        state.isSuccess = true
        state.isLoading = false
      })
      .addCase(getAllMessages.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.error = action.payload
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        console.log('Sended message to server')
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.error = action.payload
        console.log('isError')
      })
  },
})

export const { addMessage, removeLastMessage } = ChatSlice.actions
export default ChatSlice.reducer
