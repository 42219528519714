const luhn = (cnnr) => {
  var d,
    i,
    total = 0

  //Take each digit of cnnr. Start with LSD, go towards MSD
  for (i = 1; i <= cnnr.length; ++i) {
    d = +cnnr.charAt(cnnr.length - i)

    if (isNaN(d)) {
      return false //non-numeric data can't pass
    }

    if (i % 2 === 1) {
      //odd digits are just added
      total += d
      continue
    }

    //even digits are doubled
    var lValue = 2 * d

    //if value is > 9 its digits are summed
    if (lValue > 9) {
      //(for 2 digits number -= 9 works as digits sum)
      lValue -= 9
    }

    total += lValue
  }

  return total % 10 === 0 // check if total is divisible by 10
}

export const validateCNNR = (card) => {
  const cnnr = card.replaceAll('-', '')
  var $BANK_CCNR_REGEXP = new RegExp(
    [
      '^(4\\d{11}|', //VISA
      '5[1-5]\\d{10}|', //MC
      '6(011|5[0-9]{2})\\d{8}|', //Discover Card
      '3[47]\\d{9}|', //American Express
      '3(0[0-5]|[68]\\d)\\d{7}|', //Diners Club (CarteBlanche)
      '(2131|1800|35\\d{3})\\d{7})', //JCB
      '(\\d{4})$',
    ].join('')
  )

  if ($BANK_CCNR_REGEXP.test(cnnr)) {
    return luhn(cnnr)
  } else {
    return false
  }
}
