import React, { useEffect, useState } from 'react'

import { Loader } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'

import { useParams } from 'react-router-dom'
import { registerClient, reset as resetAuth } from '../../redux/authSlice'
import { changeStep, selectRegister, reset } from '../../redux/registerSlice'

const PayingStep = () => {
  const dispatch = useDispatch()
  const { topicId } = useParams()

  const { packages, cardDetails, clientDetails } = useSelector(selectRegister)
  const { isError, error } = useSelector((state) => state.auth)

  const register = () => {
    if (!packages || !cardDetails || !clientDetails) return

    const req = {
      ...cardDetails,
      amount: packages.price,
      ...clientDetails,
      topicId,
    }

    dispatch(registerClient(req))
  }

  useEffect(() => {
    register()
  }, [])

  useEffect(() => {
    if (isError && error === 'Password required') {
      dispatch(changeStep('existing'))
      dispatch(resetAuth())
    }

    if (isError && error !== 'Password required') {
      dispatch(changeStep('fail'))
      dispatch(resetAuth())
    }
  }, [isError, error, dispatch])

  return (
    <>
      <div className="payment__info">
        <h2>Please wait...</h2>
        <h2 className="t-red">Don’t exit!</h2>
        <p>We are processing your payment, give us a moment...</p>
      </div>

      <div className="loading">
        <div className="loader">
          <Loader color="#B4B7BD" size="64" />
        </div>
      </div>
    </>
  )
}

export default PayingStep
