import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../Modal'
import Lock from '../../assets/images/lock.png'
import {
  backOnPaymentModal,
  resetPayment,
  setNewCardPayment,
} from '../../redux/paymentSlice'
import { validateCNNR } from '../../utility/payment/card'

const NewCardModal = () => {
  const dispatch = useDispatch()

  const { newCardModal } = useSelector((state) => state.payment)

  const [cardNum, setCardNum] = useState('')
  const [cardNumError, setCardNumError] = useState(false)

  const [cvc, setCvc] = useState('')
  const [cvcError, setCvcError] = useState(false)

  const [month, setMonth] = useState('')
  const [monthError, setMonthError] = useState(false)

  const [year, setYear] = useState('')
  const [yearError, setYearError] = useState(false)

  const handleChange = (event) => {
    const rawValue = event.target.value
    let formattedValue = ''

    if (rawValue) {
      formattedValue = rawValue
        .replace(/[^0-9]/gi, '')
        .match(/.{1,4}/g)
        .join('-')
    }

    setCardNum(formattedValue)
  }

  const years = Array.from(
    { length: 11 },
    (_, index) => new Date().getFullYear() + index
  )
  const months = Array.from({ length: 12 }, (_, index) => index + 1)

  const handleSubmit = () => {
    setCardNumError(cardNum === '' || !validateCNNR(cardNum) ? true : false)
    setCvcError(cvc === '' ? true : false)
    setYearError(year === '' ? true : false)
    setMonthError(month === '' ? true : false)

    if (
      cardNum === '' ||
      !validateCNNR(cardNum) ||
      cvc === '' ||
      year === '' ||
      month === ''
    )
      return

    dispatch(
      setNewCardPayment({
        cardNumber: cardNum,
        cvc,
        expYear: year.slice(-2),
        expMonth: ('0' + month).slice(-2),
        isNewCard: true,
      })
    )
  }

  return (
    <Modal
      hide={!newCardModal}
      disableClose
      onClose={() => dispatch(resetPayment())}
    >
      <div className="credit-card-modal">
        <div>
          <h3>Add new card</h3>
          <p>Maximize your potential with more talking time.</p>
        </div>

        <div className="credit-card">
          <div className="credit-card__form">
            <label>
              Credit card number <span>*</span>
            </label>
            <div className={`input ${cardNumError && 'error'}`}>
              <input
                value={cardNum}
                onChange={handleChange}
                type="text"
                placeholder="XXXX - XXXX - XXXX - XXXX"
              />
            </div>
          </div>
          <div className="credit-card__form">
            <label>
              CVC <span>*</span>
            </label>
            <div className={`input ${cvcError && 'error'}`}>
              <input
                value={cvc}
                onChange={(e) => setCvc(e.target.value)}
                type="text"
                placeholder="Example. 123"
              />
            </div>
          </div>
          <div className="credit-card__form">
            <label>
              Expire date (MONTH/YEAR)<span>*</span>
            </label>
            <div className="date">
              <div className={`select__wraper ${yearError && 'error'}`}>
                <select
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  placeholder="Year"
                  id="year"
                >
                  <option value="">Year</option>
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
              <div className={`select__wraper ${monthError && 'error'}`}>
                <select
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                  placeholder="Month"
                  id="month"
                >
                  <option value="">Month</option>
                  {months.map((monthNum) => (
                    <option key={monthNum} value={monthNum}>
                      {monthNum}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <p className="lock">
            <img src={Lock} alt="lock" /> Your payment info will be stored
            securely.
          </p>
        </div>

        <div className="btns">
          <button
            className="btn-secondary grey"
            onClick={() => dispatch(backOnPaymentModal())}
          >
            Back
          </button>
          <button className="btn-primary" onClick={() => handleSubmit()}>
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default NewCardModal
