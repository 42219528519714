import React from 'react'
import logoutBtn from '../../assets/images/log-out.png'
import { logoutClient } from '../../redux/authSlice'
import { selectLayouts, toggleLogout } from '../../redux/layoutSlice'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../Modal'

const LogoutModal = () => {
  const dispatch = useDispatch()

  const { openLogout } = useSelector(selectLayouts)

  return (
    <Modal
      hide={!openLogout}
      disableClose
      onClose={() => {
        console.log('close')
      }}
    >
      <div className="simple-modal">
        <div className="simple-modal__content">
          <img src={logoutBtn} alt="logoutBtn" />
          <h3>Log me out?</h3>
          <p>
            Are you sure you want to logout? Logging out means that you will
            need to login again next time you choose to talk.
          </p>
        </div>

        <div className="btns">
          <button
            onClick={() => {
              dispatch(toggleLogout())
              dispatch(logoutClient())
            }}
            className="btn-primary"
          >
            Yes, log me out
          </button>
          <button
            onClick={() => dispatch(toggleLogout())}
            className="btn-secondary"
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default LogoutModal
