import React, { useEffect, useState } from 'react'
import { MessageSquare, PhoneCall, Video } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectLayouts,
  toggleChatVideo,
  togglePayment,
} from '../../redux/layoutSlice'
import { addAction } from '../../redux/registerSlice'
import Payment from '../Payment/index'
import UserInfo from '../UserInfo'
import NonAuthChat from './NonAuthChat'
import Login from '../Login'
import PasswordResetModal from '../Modals/PasswordResetModal'
import EmailSendModal from '../Modals/EmailSendModal'

const NonAuthContent = () => {
  const dispatch = useDispatch()

  const onAction = (action, msg) => {
    const actionForSave = {
      name: action,
      msg,
    }

    dispatch(addAction(actionForSave))
    dispatch(togglePayment())
  }

  const { chatVideo } = useSelector(selectLayouts)
  const { channels: displayRules } = useSelector((state) => state.info.data)

  return (
    <>
      <PasswordResetModal />
      <EmailSendModal />
      <main>
        <Login />
        <Payment />
        <UserInfo />
        {/* Main */}

        {chatVideo && <NonAuthChat onAction={onAction} />}

        {!chatVideo && (
          <div className="talk">
            <h2>Let’s Talk</h2>

            {displayRules.includes('audio') && (
              <button
                className="c-button video"
                onClick={() => onAction('videoCall')}
              >
                <PhoneCall />
                Start Audio Call
              </button>
            )}
            {displayRules.includes('video') && (
              <button
                className=" c-button audio"
                onClick={() => onAction('audioCall')}
              >
                <Video />
                Start Video Call
              </button>
            )}

            {displayRules.length === 1 && displayRules[0] === 'chat' && (
              <button
                className=" c-button audio"
                onClick={() => {
                  dispatch(toggleChatVideo(true))
                }}
              >
                <MessageSquare size={18} />
                Start Conversation
              </button>
            )}
          </div>
        )}

        {chatVideo &&
          displayRules.includes('chat') &&
          displayRules.length === 1 && (
            <div className="footer">
              <button
                style={{ width: '100%', borderRadius: '0' }}
                onClick={() => {
                  dispatch(toggleChatVideo(false))
                }}
              >
                Back
              </button>
            </div>
          )}

        {/* Nav footer */}
        {displayRules.includes('chat') && displayRules.length > 1 && (
          <div className="footer">
            <button
              className={!chatVideo ? 'active' : ''}
              onClick={() => dispatch(toggleChatVideo(false))}
            >
              <Video size={18} />
            </button>
            <button
              className={chatVideo ? 'active' : ''}
              onClick={() => dispatch(toggleChatVideo(true))}
            >
              <MessageSquare size={18} />
            </button>
          </div>
        )}
      </main>
    </>
  )
}

export default NonAuthContent
