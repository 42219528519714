import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
  lastAction: null,
  step: 'packages',
  packages: null,
  cardDetails: null,
  clientDetails: null,
}

export const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    reset: (state, action) => {
      state.step = 'packages'
      state.packages = null
      state.cardDetails = null
      state.clientDetails = null
    },
    addAction: (state, action) => {
      state.lastAction = action.payload
    },
    changeStep: (state, action) => {
      state.step = action.payload
    },
    updatePackages: (state, action) => {
      state.packages = action.payload
    },
    updateCard: (state, action) => {
      state.cardDetails = action.payload
    },
    updateClient: (state, action) => {
      state.clientDetails = action.payload
    },
  },
})

export const {
  reset,
  addAction,
  updatePackages,
  changeStep,
  updateCard,
  updateClient,
} = registerSlice.actions
export default registerSlice.reducer

export const selectRegister = (state) => state.register
