import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const API_URL = process.env.REACT_APP_SERVER_PORT + '/api/widget'

export const widgetApiSlice = createApi({
  reducerPath: 'api', // optional
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.client.token
      if (token) {
        headers.set('Content-Type', 'application/json')
        headers.set('authorization', `Bearer ${token}`)
        return headers
      }
    },
  }),
  tagTypes: ['getCredits', 'getCards'],
  endpoints: (builder) => ({
    getCredits: builder.query({
      query: () => ({
        url: `/credits`,
        method: 'GET',
      }),
      providesTags: ['getCredits'],
      transformResponse: (response) => {
        return response.data
      },
      transformErrorResponse: (response) => {
        return 'Error'
        //Todo
        return errorMsgHandler(response)
      },
    }),
    getCards: builder.query({
      query: () => ({
        url: `/cards`,
        method: 'GET',
      }),
      providesTags: ['getCards'],
      transformResponse: (response) => {
        return response.data
      },
      transformErrorResponse: (response) => {
        return 'Error'
        //Todo
        return errorMsgHandler(response)
      },
    }),
    deleteCard: builder.mutation({
      query: (cardId) => ({
        url: `/delete-card/${cardId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['getCards'],
      transformResponse: (response) => {
        return response.data
      },
      transformErrorResponse: (response) => {
        return 'Error'
        //Todo
        return errorMsgHandler(response)
      },
    }),
    createPayment: builder.mutation({
      query: (data) => ({
        url: `/payment`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['getCards'],
      transformResponse: (response) => {
        return response.data
      },
      transformErrorResponse: (response) => {
        return 'Error'
        //Todo
        return errorMsgHandler(response)
      },
    }),
  }),
})

export const {
  useGetCreditsQuery,
  useDeleteCardMutation,
  useGetCardsQuery,
  useCreatePaymentMutation,
} = widgetApiSlice
