import { configureStore } from '@reduxjs/toolkit'

import auth from './redux/authSlice'
import layout from './redux/layoutSlice'
import register from './redux/registerSlice'
import chat from './redux/chatSlice'
import call from './redux/callSlice'
import info from './redux/infoSlice'
import payment from './redux/paymentSlice'

import { widgetApiSlice } from './redux/widgetApiSlice'

export const store = configureStore({
  reducer: {
    info,
    auth,
    layout,
    register,
    chat,
    call,
    payment,
    [widgetApiSlice.reducerPath]: widgetApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat(widgetApiSlice.middleware)
  },
  devTools: true,
})
