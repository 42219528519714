import axios from 'axios'

export const axiosAuth = (thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.client.token
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVER_PORT + '/api',
  })

  axiosInstance.interceptors.request.use((config) => {
    config.headers = {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
    }

    return config
  })

  return axiosInstance
}
