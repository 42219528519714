import React, { useEffect, useRef, useState } from 'react'
import 'webrtc-adapter'
import '../../assets/styles/VideoCall.scss'
import {
  Phone,
  Video,
  Mic,
  Volume2,
  MessageSquare,
  MoreVertical,
  Maximize,
  Minimize,
  Monitor,
} from 'react-feather'

import Chat from './AuthChat'
import BgVideo from '../../assets/images/BgVideo.svg'

import UserInfo from '../UserInfo'
import { useSocket } from '../../utility/context/socketContext'

import { useDispatch, useSelector } from 'react-redux'
import {
  endCallAction,
  onAcceptCall,
  onAnotherDevice,
  onIncomingCall,
  onOffer,
  onPayWarning,
  onStartRinging,
  onStopCalling,
  onStopRinging,
  onUserBusy,
} from '../../redux/callSlice'
import { openPaymentModalInCall } from '../../redux/paymentSlice'
import { async } from 'q'

const VideoCall = () => {
  const socket = useSocket()

  const dispatch = useDispatch()

  const [size, setSize] = useState(false)
  const [chatActive, setChatActive] = useState(false)

  const {
    callStarted,
    isRinging,
    partnerTypeOffer,
    localTypeOffer,
    partnerSocketID,
    typeOfCall,
    offer,
    payWarning,
  } = useSelector((state) => state.call)

  const advisorId = useSelector((state) => state.info.data.advisorId)

  const peerRef = useRef()

  const [localMode, setLocalMode] = useState('audio')
  const localStream = useRef()

  const [partnerMode, setPartnerMode] = useState('ringing')
  const partnerStream = useRef()

  const needRenegotiating = useRef()

  const addVideoTrack = useRef()

  const [anotherDevice, setAnotherDevice] = useState(false)

  useEffect(() => {
    socket.on('userBusy', () => {
      setLocalMode('audio')

      dispatch(onUserBusy())
    })

    socket.on('answer-on-another-device', () => {
      dispatch(onAnotherDevice())
      setAnotherDevice(true)
      setTimeout(() => {
        setAnotherDevice(false)
      }, 1200)
    })

    socket.on('ring', (payload) => {
      dispatch(onStartRinging(payload))
    })

    socket.on('stopRinging', () => {
      dispatch(onStopRinging())
    })

    socket.on('declinedCall', () => {
      dispatch(onStopCalling())
    })

    socket.on('callAccepted', async (payload) => {
      dispatch(onAcceptCall(payload))
    })

    socket.on('offer', (data) => {
      dispatch(onOffer(data))
    })

    socket.on('answer', handleAnswer)

    socket.on('ice-candidate', handleNewICECandidateMsg)

    socket.on('callEnd', () => {
      endCallHandler()
    })

    socket.on('toggleVideo', (state) => {
      setPartnerMode(state ? 'video' : 'audio')
    })

    socket.on('micro_startCall', (timeLeft) => {
      setTimeLeft(timeLeft)
      setIsCountdownActive(true)
    })

    socket.on('micro_end_call', () => {
      dispatch(onStopCalling())
    })

    return () => {
      socket.off('userBusy')
      socket.off('ring')
      socket.off('stopRinging')
      socket.off('declinedCall')
      socket.off('callAccepted')
      socket.off('offer')
      socket.off('answer')
      socket.off('ice-candidate')
      socket.off('callEnd')
      socket.off('toggleVideo')
      socket.off('answer-on-another-device')
      socket.off('micro_startCall')
      socket.off('micro_end_call')
    }
  }, [])

  // useEffect handle call user and recieve call
  // *********************************
  useEffect(() => {
    if (typeOfCall === 'caller') {
      callUser()
    }
  }, [typeOfCall])

  useEffect(() => {
    if (offer && !offer.onTrack) {
      handleRecieveCall(offer.payload)
    }
    if (offer && offer.onTrack) {
      console.log('handleRecieveTrack')
      handleRecieveTrack(offer.payload)
    }
  }, [offer])
  // *********************************

  const configuration = {
    iceServers: [
      {
        urls: 'stun:185.97.53.11:3478',
      },
      {
        urls: 'turn:185.97.53.11:3478',
        username: 'infomedia',
        credential: 'c7A2dTBZME2434xR',
      },
    ],
  }

  const getUserMedia = async (withVideo) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: withVideo,
      })
      localStream.current.srcObject = stream

      return stream
    } catch (error) {
      console.log(error)
    }
  }

  const callUser = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: localTypeOffer === 'video' ? true : false,
      })

      localStream.current.srcObject = stream
      await createPeer()
    } catch (error) {
      console.log(error)
    }
  }

  const createPeer = async () => {
    const peer = new RTCPeerConnection(configuration)

    peerRef.current = peer

    localStream.current.srcObject
      .getTracks()
      .forEach((track) => peer.addTrack(track, localStream.current.srcObject))

    const offer = peer.createOffer()
    await peer.setLocalDescription(offer)

    console.log('offerrr---------------')
    console.log(await offer)
    console.log('offerrr---------------')
    const payload = {
      target: partnerSocketID,
      caller: socket.id,
      sdp: peer.localDescription,
    }
    socket.emit('offer', {
      payload,
      onTrack: addVideoTrack.current ? true : false,
    })

    // Ice candidates
    peer.onicecandidate = (e) => {
      if (e.candidate) {
        const payload = {
          target: partnerSocketID,
          candidate: e.candidate,
        }

        socket.emit('ice-candidate', payload)
      } else {
        console.log('Stanje generiranja ICE kandidata:', peer.iceGatheringState)
      }
    }

    peer.onicegatheringstatechange = function (event) {
      console.log(
        'Stanje generiranja ICE kandidata se promijenilo:',
        peer.iceGatheringState
      )
    }

    // On Track
    peer.ontrack = (e) => {
      if (e.track.kind === 'video') setPartnerMode('video')
      partnerStream.current.srcObject = e.streams[0]
    }

    peer.onconnectionstatechange = (e) => {
      console.log(e)
      console.log('Peer - ' + peer.connectionState)

      if (peer.connectionState === 'connected') {
        socket.emit('callConnected', {
          clientSocketId: socket.id,
          advisorSocketId: partnerSocketID,
          advisorId,
        })
        setPartnerMode(partnerTypeOffer)
        setLocalMode(localTypeOffer)

        if (needRenegotiating.current) {
          addVideoStream()
        }
      }
    }

    return peer
  }

  const handleRecieveCall = async (incoming) => {
    peerRef.current = createPeer()
    const desc = new RTCSessionDescription(incoming.sdp)
    await peerRef.current.setRemoteDescription(desc)
    localStream.current.srcObject
      .getTracks()
      .forEach((track) =>
        peerRef.current.addTrack(track, localStream.current.srcObject)
      )
    const answer = await peerRef.current.createAnswer()
    await peerRef.current.setLocalDescription(answer)

    const payload = {
      target: partnerSocketID,
      caller: socket.id,
      sdp: peerRef.current.localDescription,
    }
    socket.emit('answer', payload)
  }

  const handleRecieveTrack = async (incoming) => {
    const desc = new RTCSessionDescription(incoming.sdp)
    await peerRef.current.setRemoteDescription(desc)
    const answer = await peerRef.current.createAnswer()
    await peerRef.current.setLocalDescription(answer)

    const payload = {
      target: partnerSocketID,
      caller: socket.id,
      sdp: peerRef.current.localDescription,
    }
    socket.emit('answer', payload)
  }

  function handleAnswer(message) {
    const desc = new RTCSessionDescription(message.sdp)
    peerRef.current.setRemoteDescription(desc).catch((e) => console.log(e))
  }

  function handleNewICECandidateMsg(incoming) {
    const candidate = new RTCIceCandidate(incoming)

    peerRef.current.addIceCandidate(candidate).catch((e) => console.log(e))
  }

  const acceptCall = async () => {
    await getUserMedia(localTypeOffer === 'video')
    socket.emit('callAccept', { target: partnerSocketID, type: localTypeOffer })
    dispatch(onIncomingCall())
  }

  const declinedCall = () => {
    socket.emit('declinedCall', { target: partnerSocketID })
    dispatch(onStopRinging())
  }

  // Video stream handler
  // *********************
  const toggleVideo = async () => {
    if (
      !localStream.current.srcObject.getVideoTracks()[0] &&
      localMode === 'audio' &&
      localTypeOffer === 'audio'
    ) {
      setLocalMode('video')
      console.log('addVideoStream')
      await addVideoStream()
    } else {
      changeVideoStream()
    }
  }

  const addVideoStream = async () => {
    addVideoTrack.current = true
    const stream = await navigator.mediaDevices.getUserMedia({
      video: true,
    })

    localStream.current.srcObject.addTrack(stream.getVideoTracks()[0])

    stream.getTracks().forEach((track) => {
      peerRef.current.addTrack(track, localStream.current.srcObject)
    })
  }

  const changeVideoStream = async () => {
    const videoTrack = localStream.current.srcObject.getVideoTracks()[0]

    console.log(videoTrack.enabled)
    videoTrack.enabled = !videoTrack.enabled
    socket.emit('toggleVideo', {
      target: partnerSocketID,
      state: videoTrack.enabled,
    })
    setLocalMode(videoTrack.enabled ? 'video' : 'audio')
  }
  // *********************

  const [micEnabled, setMicEnabled] = useState(true)
  const toggleMic = () => {
    const audioTrack = localStream.current.srcObject.getAudioTracks()[0]
    audioTrack.enabled = !audioTrack.enabled
    setMicEnabled(!micEnabled)
  }

  const [partnerAudioEnabled, setPartnerAudioEnabled] = useState(true)
  const togglePartnerAudio = () => {
    partnerStream.current.muted = !partnerStream.current.muted
    setPartnerAudioEnabled(!partnerStream.current.muted)
  }

  const [displaySharing, setDisplaySharing] = useState(false)
  const screenTrackRef = useRef()
  const toggleDisplaySharing = async () => {
    if (displaySharing) {
      screenTrackRef.current.stop()
      return screenTrackRef.current.onended()
    }

    const screen = await navigator.mediaDevices.getDisplayMedia({
      cursor: true,
    })

    let existVideoTrack = false
    const screenTrack = screen.getVideoTracks()[0]
    screenTrackRef.current = screenTrack

    peerRef.current.getSenders().forEach((sender) => {
      if (sender.track?.kind === 'video') {
        sender.replaceTrack(screenTrack)
        localStream.current.srcObject = screen
        existVideoTrack = true
        setDisplaySharing(true)
      }
    })

    if (!existVideoTrack) {
      addVideoTrack.current = true
      peerRef.current.addTrack(screenTrack, localStream.current.srcObject)
      localStream.current.srcObject = screen
      setLocalMode('video')
      setDisplaySharing(true)
    }

    screenTrack.onended = function () {
      peerRef.current.getSenders().forEach((sender) => {
        if (sender.track?.kind === 'video') {
          localStream.current.srcObject = localStream.current.srcObject

          if (!existVideoTrack) {
            setLocalMode('audio')
            peerRef.current.removeTrack(sender)

            socket.emit('toggleVideo', {
              target: partnerSocketID,
              state: false,
            })
            existVideoTrack = false
            setDisplaySharing(false)
          } else {
            sender.replaceTrack(
              localStream.current.srcObject.getVideoTracks()[0]
            )
            setDisplaySharing(false)
          }
        }
      })
    }
  }

  // End call handler
  // *********************
  const endCallHandler = () => {
    setIsCountdownActive(false)
    setTimeLeft(0)
    peerRef.current.close()
    setLocalMode('audio')
    setPartnerMode('ringing')

    localStream.current.srcObject.getTracks().forEach((track) => track.stop())
    localStream.current.srcObject = null

    partnerStream.current.srcObject.getTracks().forEach((track) => track.stop())
    partnerStream.current.srcObject = null

    needRenegotiating.current = null
    addVideoTrack.current = null
    peerRef.current = null

    dispatch(endCallAction())
    socket.emit('callFinished')
  }

  const onEndCall = () => {
    if (peerRef.current) {
      socket.emit('endCall')
      return
    }

    socket.emit('stopCalling')
    dispatch(onStopCalling())
  }
  // *********************

  const [collapse, setCollapse] = useState(false)
  // Timer
  const [timeLeft, setTimeLeft] = useState(0)
  const [isCountdownActive, setIsCountdownActive] = useState(false)

  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600)
    const minutes = Math.floor((totalSeconds % 3600) / 60)
    const seconds = totalSeconds % 60

    const formattedHours = hours.toString().padStart(2, '0')
    const formattedMinutes = minutes.toString().padStart(2, '0')
    const formattedSeconds = seconds.toString().padStart(2, '0')

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
  }

  useEffect(() => {
    if (isCountdownActive) {
      const timer = setInterval(() => {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1)
      }, 1000)

      return () => {
        clearInterval(timer)
      }
    }
  }, [isCountdownActive])

  useEffect(() => {
    console.log(timeLeft)
    if (timeLeft === 60) {
      dispatch(openPaymentModalInCall('client_micro_call_payment'))
    }
  }, [timeLeft])

  return (
    <>
      {payWarning && (
        <div
          style={{
            position: 'absolute',
            zIndex: '9999999999999999999999999999',
          }}
        >
          <button
            onClick={() => {
              socket.emit('callPaying')
              dispatch(onPayWarning(false))
            }}
            style={{ padding: '22px' }}
          >
            Pay
          </button>
        </div>
      )}
      <div className={`videoCall-modal ${false ? 'show' : 'hide'}`}>
        <div className="acceptedOnAnotherDevice">
          <p>User is busy</p>
        </div>
      </div>
      <div className={`videoCall-modal ${anotherDevice ? 'show' : 'hide'}`}>
        <div className="acceptedOnAnotherDevice">
          <p>Accepted on another device</p>
        </div>
      </div>

      <div className={`videoCall-modal ${isRinging ? 'show' : 'hide'}`}>
        <div className="videoCall-ringing">
          <p>Ringing...</p>
          <p>Answer on {localTypeOffer}</p>

          <div className="btns">
            <button onClick={() => acceptCall()}>Accept call</button>
            <button onClick={() => declinedCall()}>Decline</button>
          </div>
        </div>
      </div>

      <div
        className={`videoCall-modal ${collapse && 'collapse'}   ${
          callStarted ? 'show' : 'hide'
        }`}
      >
        <div className="videoCall">
          <div
            className={`videoCall__call ${!size ? 'v-small' : 'v-expanded'}`}
          >
            <button
              className="collapse-btn"
              onClick={() => setCollapse(!collapse)}
            >
              {collapse ? (
                <Minimize color="white" size={24} />
              ) : (
                <Maximize color="white" size={24} />
              )}
            </button>
            <div className={`videosBox ${!size ? 'v-small' : 'v-expanded'}`}>
              {/* **** */}
              {/* PARTNER */}
              {/* **** */}
              <div className="partner">
                {/* Ringing */}
                <div
                  className={`ringing ${partnerMode !== 'ringing' && 'none'}`}
                >
                  <div className="ringing_box">
                    <img src={BgVideo} alt="bgVideo" />
                  </div>
                  <p>Ringing</p>
                </div>
                {/* Audio */}
                <div className={`audio ${partnerMode !== 'audio' && 'none'}`}>
                  <img src={BgVideo} alt="bgVideo" />
                </div>
                {/* Video */}
                <div className={`video ${partnerMode !== 'video' && 'none'}`}>
                  <video
                    ref={partnerStream}
                    playsInline
                    autoPlay
                    onChange={(e) => {
                      console.log(e)
                    }}
                  />
                </div>
              </div>
              {/* **** */}
              {/* LOCAL */}
              {/* **** */}
              <div className="local">
                <button onClick={() => setSize(!size)} className="size">
                  {!size ? <Maximize size={16} /> : <Minimize size={16} />}
                </button>
                <div className={`audio ${localMode !== 'audio' && 'none'}`}>
                  <img src={BgVideo} alt="bgVideo" />
                </div>
                <div className={`video ${localMode !== 'video' && 'none'}`}>
                  <video ref={localStream} playsInline muted autoPlay />
                </div>
              </div>
            </div>

            <div className="call-nav">
              <div className="nav">
                <button className="end-call" onClick={onEndCall}>
                  <Phone />
                </button>
                <button
                  disabled={displaySharing}
                  style={{
                    opacity: `${
                      localMode === 'video' && !displaySharing ? '1' : '0.3'
                    }`,
                  }}
                  onClick={toggleVideo}
                >
                  <Video />
                </button>
                <button
                  style={{ opacity: `${micEnabled ? '1' : '0.3'}` }}
                  onClick={toggleMic}
                >
                  <Mic />
                </button>
                <button
                  style={{ opacity: `${partnerAudioEnabled ? '1' : '0.3'}` }}
                  onClick={togglePartnerAudio}
                >
                  <Volume2 />
                </button>
                <button
                  className="screenSharing"
                  style={{ opacity: `${displaySharing ? '1' : '0.3'}` }}
                  onClick={toggleDisplaySharing}
                >
                  <Monitor />
                </button>
                <button onClick={() => setChatActive(!chatActive)}>
                  <MessageSquare />
                </button>
                <button>
                  <MoreVertical />
                </button>
              </div>
              <div className="timer">
                {timeLeft > 0 && (
                  <p>
                    Time left: <span>{formatTime(timeLeft)}</span>
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className={`videoCall__chat ${chatActive ? '' : 'none'}`}>
            <div className="chat-header">
              <UserInfo />
              <button onClick={() => setChatActive(false)}>Back to call</button>
            </div>
            <div className="main" style={{ display: 'contents' }}>
              <Chat />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VideoCall
