import React, { useEffect, useRef, useState } from 'react'
import 'moment-timezone'
import { useDispatch, useSelector } from 'react-redux'
import { useSocket } from '../../utility/context/socketContext'
import { addMessage, sendMessage } from '../../redux/chatSlice'
import { useParams } from 'react-router-dom'

const Chat = () => {
  const { topicId } = useParams()

  const messagesEnd = useRef()
  const socket = useSocket()
  const dispatch = useDispatch()

  // Render messages array
  const [msg, setMsg] = useState('')
  const [isTyping, setIsTyping] = useState(false)

  const { messages, id: chatId } = useSelector((state) => state.chat)
  const { id } = useSelector((state) => state.auth.client)
  const { client } = useSelector((state) => state.auth)
  const { callStarted } = useSelector((state) => state.call)

  const sendMessageHandler = (e) => {
    e.preventDefault()
    if (msg.trim() === '') return

    if (callStarted) {
      socket.emit('call_sendMessage', { chatId, msg })
    } else {
      socket.emit('sendMessage', msg)
    }

    // Change logic in future
    dispatch(
      addMessage({
        sender: client.id,
        content: msg,
        time: Date.now(),
      })
    )

    setMsg('')
  }

  const handleMessageChange = (e) => {
    const inputText = e.target.value
    if (inputText.length <= 1000) {
      setMsg(inputText)
    }
  }

  useEffect(() => {
    return () => {
      socket.off('changeStatus')
      socket.off('isTyping')
      socket.off('notTyping')
    }
  }, [])

  // Scroll to messages
  useEffect(() => {
    const messagesContainer = messagesEnd.current.parentElement
    if (messagesContainer) {
      const scrollHeight = messagesContainer.scrollHeight
      const scrollTop = messagesContainer.scrollTop
      const distance = scrollHeight - scrollTop
      const duration = 800 // duration of the scroll animation in milliseconds
      const startTime = performance.now()

      const scrollStep = (timestamp) => {
        const elapsedTime = timestamp - startTime
        const scrollFraction = Math.min(elapsedTime / duration, 1)
        const scrollPosition = scrollTop + distance * scrollFraction
        messagesContainer.scrollTop = scrollPosition
        if (elapsedTime < duration) {
          window.requestAnimationFrame(scrollStep)
        }
      }
      window.requestAnimationFrame(scrollStep)
    }
  }, [messages])

  const renderMessages = () => {
    return messages.map((item, index) => {
      if (item.isCall) {
        return (
          <div key={index} className="call-box">
            <div className={`call ${item.callState}`}>
              {item.callState === 'incoming' && (
                <h3>
                  Incoming {item.typeOfCall} call ({item.timeInCall})
                </h3>
              )}
              {item.callState === 'missed' && <h3>Missed call</h3>}
              <span>{item.time}</span>
            </div>
          </div>
        )
      } else {
        return (
          <div
            key={index}
            className={`message-box ${id === item.sender ? 'me' : ''}`}
          >
            <div className="message">{item.content}</div>
          </div>
        )
      }
    })
  }

  return (
    <div className="chat">
      <div className="messages" id="chat">
        {renderMessages()}
        {isTyping && (
          <div className="message-box">
            <div className="message">
              <div className="typing typing-1"></div>
              <div className="typing typing-2"></div>
              <div className="typing typing-3"></div>
            </div>
          </div>
        )}
        <div ref={messagesEnd} style={{ float: 'left', clear: 'both' }}></div>
      </div>
      <form onSubmit={sendMessageHandler} className="send-message">
        <input
          placeholder="Type your message here!"
          type="text"
          value={msg}
          onChange={(e) => handleMessageChange(e)}
        />
        <button type="submit">Send</button>
      </form>
    </div>
  )
}

export default Chat
