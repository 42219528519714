import React, { useEffect, useState } from 'react'

import { useGoogleLogin } from '@react-oauth/google'
import googleLogo from '../assets/images/google_logo.png'
import axios from 'axios'
import { Loader } from 'react-feather'

import '../assets/styles/login.scss'
import { useParams } from 'react-router-dom'

import redLock from '../assets/images/lock-red.png'
import { useDispatch, useSelector } from 'react-redux'
import { loginClient } from '../redux/authSlice.js'
import { selectLayouts, toggleLogin } from '../redux/layoutSlice'

const Login = () => {
  const { openLogin } = useSelector(selectLayouts)
  const { topicId } = useParams()

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)

  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(false)

  const [openModal, setOpenModal] = useState(false)

  const dispatch = useDispatch()

  // Login
  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const { data } = await axios.get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
            Accept: 'application/json',
          },
        }
      )

      onLogin('1', data.email)
    },
    onError: (error) => console.error('Login failed:', error),
  })

  const isValidEmail = (email) => {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const onLogin = async (isGoogle = '0', googleEmail) => {
    if (isGoogle === '0') {
      if (!isValidEmail(email)) {
        setEmailError(true)
        return
      } else {
        setEmailError(false)
      }

      if (password.trim() === '') {
        setPasswordError(true)
        return
      } else {
        setPasswordError(false)
      }
    }

    const req =
      isGoogle === '1'
        ? {
            email: googleEmail,
            google: isGoogle,
            topic_uuid: topicId,
          }
        : {
            email,
            password,
            google: isGoogle,
            topic_uuid: topicId,
          }

    dispatch(loginClient(req))
  }

  const onResetPass = () => {
    console.log('Reset password handler')
    setOpenModal(false)
  }

  const { isAuth, isLoading, isError, isSuccess, error } = useSelector(
    (state) => state.auth
  )

  return (
    <>
      {openModal && (
        <div className="modal">
          <div className="inner">
            <div className="content">
              <div className="reset-pass">
                <img src={redLock} alt="redLock" />
                <h3>Password reset?</h3>
                <p>
                  If you have forgotten your password, don't worry! You can
                  easily reset it and we'll send instructions to your email.
                </p>
              </div>

              <div className="content__btns">
                <button onClick={() => setOpenModal(false)} className="cancel">
                  Cancel
                </button>
                <button onClick={onResetPass} className="success">
                  Reset password
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={`login ${!openLogin ? 'closed' : ''}`}>
        {isLoading && (
          <div className="loading loading-email">
            <div className="loader">
              <Loader color="#B4B7BD" size="64" />
            </div>
          </div>
        )}
        <div className="payment__info">
          <h2>Login</h2>
          <p>
            Pick up right where you left off with quick and easy login to your
            account. Just a few clicks and you'll be back to using our platform
            as if you never left
          </p>
        </div>
        <div className="auth">
          <div className="card__form">
            <label>
              Email <span>*</span>
            </label>
            <div className={`input ${emailError && 'error'}`}>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Email"
              />
            </div>
          </div>
          <div className="card__form">
            <div className="forgot-pass">
              <label>
                Password <span>*</span>
              </label>
              <button onClick={() => setOpenModal(true)}>
                Forgot Password?
              </button>
            </div>
            <div className={`input ${passwordError && 'error'}`}>
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder="Input your password"
              />
            </div>
          </div>

          {error && (
            <h2 style={{ color: 'red', textAlign: 'center' }}>{error}</h2>
          )}

          <div className="btn" onClick={() => onLogin()}>
            Login
          </div>

          <div className="or">Or</div>

          <button className="google_btn" onClick={googleLogin}>
            <img src={googleLogo} alt="googleLogo" />
            Sign in with Google
          </button>
        </div>
      </div>
    </>
  )
}

export default Login
