import arrDown from '../../assets/images/arr-down.png'

import { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import {
  toggleWidget,
  toggleLogin,
  toggleLogout,
} from '../../redux/layoutSlice'

const WidgetHeader = () => {
  const dispatch = useDispatch()
  const { isAuth } = useSelector((state) => state.auth)

  return (
    <>
      <div className="header">
        {isAuth && (
          <button
            className="login-btn"
            onClick={() => dispatch(toggleLogout())}
          >
            Logout
          </button>
        )}
        {!isAuth && (
          <button className="login-btn" onClick={() => dispatch(toggleLogin())}>
            Login
          </button>
        )}

        <h5>Web Call</h5>
        <button className="down" onClick={() => dispatch(toggleWidget(false))}>
          <img src={arrDown} alt="arrDown" />
        </button>
      </div>
    </>
  )
}

export default WidgetHeader
