import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
  openWidget: false,
  openPayment: false,
  openLogin: false,
  openLogout: false,
  chatVideo: false,
  existSession: false,
}

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    toggleWidget: (state, action) => {
      window.parent.postMessage(action.payload, '*')
      state.openWidget = action.payload
    },
    toggleLogin: (state, action) => {
      state.openLogin = !state.openLogin
    },
    toggleLogout: (state, action) => {
      state.openLogout = !state.openLogout
    },
    togglePayment: (state, action) => {
      state.openPayment = !state.openPayment
    },
    toggleChatVideo: (state, action) => {
      state.chatVideo = action.payload
    },
    turnOnExistSession: (state) => {
      state.existSession = true
    },
  },
})

export const {
  toggleWidget,
  toggleLogin,
  toggleLogout,
  toggleChatVideo,
  turnOnExistSession,
  togglePayment,
} = layoutSlice.actions
export default layoutSlice.reducer

export const selectLayouts = (state) => state.layout
