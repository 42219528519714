import React from 'react'
import LockGreen from '../../assets/images/lock-green.png'
import { logoutClient } from '../../redux/authSlice'
import { selectLayouts, toggleLogout } from '../../redux/layoutSlice'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../Modal'

const EmailSendModal = () => {
  const dispatch = useDispatch()

  // const { openPasswordReset } = useSelector(selectLayouts)

  const openPasswordReset = false

  return (
    <Modal
      hide={!openPasswordReset}
      disableClose
      onClose={() => {
        console.log('close')
      }}
    >
      <div className="simple-modal">
        <div className="simple-modal__content">
          <img src={LockGreen} alt="LockGreen" />
          <h3>Email sent</h3>
          <p>
            Check your mail and open the link we sent to continue. Once you've
            reset your password, simply come back to continue.
          </p>
        </div>

        <div className="btns">
          <button onClick={() => {}} className="btn-primary">
            Close
          </button>
          <button className="btn-secondary">Go to email</button>
        </div>
      </div>
    </Modal>
  )
}

export default EmailSendModal
