import React from 'react'

import '../assets/styles/temporarily-service.scss'

function TemporarilyService() {
  return (
    <div className="temporarilyService">
      <div className="animation">
        <div className="one spin-one"></div>
        <div className="two spin-two"></div>
        <div className="three spin-one"></div>
      </div>
      <h3>Service temporarily unavailable</h3>
    </div>
  )
}

export default TemporarilyService
