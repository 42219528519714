import React, { createContext, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import Loading from '../../components/Loading'

import io from 'socket.io-client'
import { getAllMessages } from '../../redux/chatSlice'
import { turnOnExistSession } from '../../redux/layoutSlice'
import TemporarilyService from '../../components/TemporarilyService'
import { useGetCreditsQuery } from '../../redux/widgetApiSlice'
import { updateCredits } from '../../redux/authSlice'
import { changeStatus } from '../../redux/infoSlice'

const SocketContext = createContext()

export const useSocket = () => useContext(SocketContext)

export const SocketProvider = ({ children }) => {
  // Connect on socket
  const [socket, setSocket] = useState(null)
  const [socketError, setSocketError] = useState(null)
  const [isConnect, setIsConnect] = useState(null)

  const { topicId } = useParams()
  const { client } = useSelector((state) => state.auth)

  const { data: advisorData } = useSelector((state) => state.info)
  const { isLoading, isSuccess } = useSelector((state) => state.chat)

  const dispatch = useDispatch()

  const { data: creditsData, isSuccess: creditsIsSuccess } =
    useGetCreditsQuery()

  useEffect(() => {
    if (isConnect) dispatch(getAllMessages(topicId))

    if (creditsIsSuccess) {
      dispatch(updateCredits(creditsData.credits))
    }
  }, [isConnect, creditsIsSuccess])

  const [loader, setLoader] = useState(true)

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_SOCKET_PORT, {
      query: {
        token: client.token,
        identifier: 'CLIENT',
        advisorId: advisorData.advisorId,
        clientId: client.id,
        topicId,
      },
    })
    setSocket(newSocket)

    return () => newSocket.close()
  }, [])

  useEffect(() => {
    console.log(isConnect)
    if (socket) {
      socket.on('connect', () => {
        setSocketError(false)
        setIsConnect(true)
        console.log('Socket is connected...')
        setLoader(false)
      })

      socket.on('micro_advisor_status', ({ status }) => {
        console.log('change_advisor_status')
        dispatch(changeStatus({ status }))
      })

      socket.on('disconnect', () => {
        setIsConnect(false)
        console.log('Socket is disconnected...')
      })

      socket.on('connect_error', (err) => {
        setIsConnect(false)
        setSocketError(true)
        console.log(err)
        console.log(`Socket error to connect...`)
        setLoader(false)
      })

      socket.on('existSession', () => {
        dispatch(turnOnExistSession())
      })
    }

    return () => {
      socket?.off('connect')
      socket?.off('change_advisor_status')
      socket?.off('disconnect')
      socket?.off('connect_error')
    }
  }, [socket, isConnect])

  // console.log(loader)

  return (
    <SocketContext.Provider value={socket}>
      {loader || isLoading ? (
        <Loading />
      ) : isSuccess && isConnect ? (
        children
      ) : (
        <TemporarilyService />
      )}
    </SocketContext.Provider>
  )
}
