import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../Modal'
import { Loader } from 'react-feather'
import { useCreatePaymentMutation } from '../../redux/widgetApiSlice'
import { updateCredits } from '../../redux/authSlice'
import { resetPayment } from '../../redux/paymentSlice'
import { useSocket } from '../../utility/context/socketContext'

const WaitModal = () => {
  const dispatch = useDispatch()
  const socket = useSocket()

  const {
    cardId,
    isNewCard,
    amount,
    cardNumber,
    expYear,
    expMonth,
    cvc,
    socketEmit,
  } = useSelector((state) => state.payment)

  const [createPayment, { data, isSuccess }] = useCreatePaymentMutation()

  useEffect(() => {
    let req = {}

    if (isNewCard) {
      req = {
        cardNumber,
        expYear,
        expMonth,
        cvc,
        amount,
      }
    } else {
      req = {
        cardId,
        amount,
      }
    }

    createPayment(req)
  }, [])

  useEffect(() => {
    if (isSuccess) {
      dispatch(updateCredits(data.credits))
      dispatch(resetPayment())
      if (socketEmit) {
        console.log(socketEmit)
        socket.emit(socketEmit)
      }
    }
  }, [isSuccess])

  return (
    <Modal
      disableClose
      onClose={() => {
        console.log('close')
      }}
    >
      <div className="wait-modal">
        <div>
          <h3>Please wait...</h3>
          <h4>Don’t exit!</h4>
          <p> We are processing your payment, give us a moment...</p>
        </div>

        <div className="loading">
          <div className="loader">
            <Loader color="#B4B7BD" size="64" />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default WaitModal
