import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeStep } from '../../redux/registerSlice'

import failImg from '../../assets/images/fail_step.png'

const FailStep = () => {
  const dispatch = useDispatch()

  const { error } = useSelector((state) => state.auth)

  return (
    <div className="fail">
      <div className="wrong">
        <p>Something went wrong!</p>
        <p>{error}</p>
        <p>We're sorry, but your payment was not successful. </p>
      </div>

      <img src={failImg} alt="failImg" />

      <div className="fail__info">
        <p>
          This may have been caused by an expired card or insufficient funds.
        </p>
        <p>
          Please try again with the same card or a different one to complete
          your transaction.
        </p>
      </div>
      <button
        className="try-again"
        onClick={() => dispatch(changeStep('card'))}
      >
        Try again
      </button>
    </div>
  )
}

export default FailStep
