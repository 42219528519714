import React, { useState } from 'react'
import 'moment-timezone'

const Chat = ({ onAction }) => {
  const [msg, setMsg] = useState('')

  const sendMessage = async (e) => {
    e.preventDefault()

    onAction('sendMessage', msg)

    setMsg('')
  }

  return (
    <div className="chat">
      <div className="messages" id="chat">
        <div style={{ float: 'left', clear: 'both' }}></div>
      </div>
      <form onSubmit={sendMessage} className="send-message">
        <input
          placeholder="Type your message here!"
          type="text"
          value={msg}
          onChange={(e) => setMsg(e.target.value)}
        />
        <button type="submit">Send</button>
      </form>
    </div>
  )
}

export default Chat
