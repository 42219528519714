import React, { useEffect, useState } from 'react'
import Modal from '../Modal'

import Visa from '../../assets/images/cards/visa.png'
import { useDispatch, useSelector } from 'react-redux'
import {
  openAddNewCard,
  openDeleteCard,
  openWaitModal,
  resetPayment,
  setAmountHandler,
  setCardIdHandler,
} from '../../redux/paymentSlice'
import { useGetCardsQuery } from '../../redux/widgetApiSlice'

const packages = [
  {
    id: 1,
    name: 'Basic',
    duration: 10,
    credit: 5,
  },
  {
    id: 2,
    name: 'Standard',
    duration: 30,
    credit: 15,
  },
  {
    id: 3,
    name: 'Premium',
    duration: 60,
    credit: 30,
  },
  {
    id: 4,
    name: 'Pro',
    duration: 90,
    credit: 45,
  },
  {
    id: 5,
    name: 'Ultimate',
    duration: 180,
    credit: 90,
  },
]

const PaymentModal = () => {
  const dispatch = useDispatch()
  const [packId, setPackId] = useState(1)

  const [minutes, setMinutes] = useState('')

  const { paymentModalText } = useSelector((state) => state.payment)

  const [selectedCard, setSelectedCard] = useState(null)
  const { data: cardData, isSuccess: cardIsSuccess } = useGetCardsQuery()

  useEffect(() => {
    dispatch(setAmountHandler(packages[0].credit))
  }, [])

  useEffect(() => {
    if (cardIsSuccess && cardData.length > 0) {
      setSelectedCard(cardData[0])
      dispatch(setCardIdHandler(cardData[0].id))
    }
  }, [cardIsSuccess])

  return (
    <Modal onClose={() => dispatch(resetPayment())}>
      <div className="payment-modal">
        <div>
          <h2>{paymentModalText ? paymentModalText : `Time's almost up!`}</h2>
          <h3>Get more time by selecting a new package.</h3>
        </div>
        <div className="payment-package">
          <div className="packages">
            <div className="payment-packs">
              {packages.map((el, i) => (
                <button
                  onClick={() => {
                    setMinutes('')
                    setPackId(el.id)
                    dispatch(setAmountHandler(el.credit))
                  }}
                  key={i}
                  className={`pack ${el.id === packId ? 'selected' : ''}`}
                >
                  <h4>{el.name}</h4>
                  <h5>{el.duration} minutes</h5>
                  <h6>{el.credit} €</h6>
                </button>
              ))}
            </div>
          </div>

          <p className="choose">Or choose our custom package:</p>
          <div className="input">
            <input
              onChange={(e) => {
                const inputValue = e.target.value
                setPackId(null)
                dispatch(setAmountHandler(Number(e.target.value)))
                setMinutes(inputValue)
              }}
              value={minutes}
              type="number"
              placeholder="e.g. 50"
            />
            <p>minutes</p>
          </div>

          <div className="info">
            <div>
              <span>min.</span>
              <p>5 minutes</p>
            </div>
            <div>
              <span>max.</span>
              <p>120 minutes</p>
            </div>
          </div>
        </div>

        <div className="payment-cards">
          {cardIsSuccess &&
            cardData.length > 0 &&
            cardData.map((el, i) => {
              return (
                <div
                  onClick={() => {
                    setSelectedCard(el)
                    dispatch(setCardIdHandler(el.id))
                  }}
                  key={el.id}
                  className={`card ${
                    selectedCard?.id === el.id ? 'selected' : ''
                  }`}
                >
                  <div>
                    <img src={Visa} alt="Visa" />
                    <span>**** {el.last4}</span>
                  </div>
                  <div>
                    <button onClick={() => dispatch(openDeleteCard(el.id))}>
                      Delete
                    </button>
                    <div className="delete"></div>
                    <span>
                      Card expires at {el.expMonth}/{el.expYear}
                    </span>
                  </div>
                </div>
              )
            })}
        </div>

        <div className={`btns ${cardData?.length === 0 ? 'single' : ''}`}>
          <button
            onClick={() => dispatch(openAddNewCard())}
            className="btn-secondary"
          >
            Add new card
          </button>

          {cardData?.length > 0 && (
            <button
              className="btn-primary"
              onClick={() => dispatch(openWaitModal())}
            >
              Confirm
            </button>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default PaymentModal
