import React from 'react'

const ExistSession = () => {
  return (
    <div
      style={{
        position: 'absolute',
        zIndex: '9999',
        background: '#751313',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: '20px',
        padding: '20px',
        color: '#fff',
      }}
    >
      <p>
        Session with this advisor exist on another topic, please close another
        seesion, and reload this page
      </p>
    </div>
  )
}

export default ExistSession
