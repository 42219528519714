import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../utility/api/axios'

export const getInfo = createAsyncThunk(
  'info/getInfo',
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`/widget/info/${id}`)

      return response.data
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

const initialState = {
  data: null,
  currentStatus: 'away',
  isSuccess: false,
  isLoading: false,
  isError: false,
  errorMessage: '',
}

export const InfoSlice = createSlice({
  name: 'info',
  initialState,
  reducers: {
    changeStatus: (state, action) => {
      console.log(action.payload)
      state.currentStatus = action.payload.status
    },
  },
  extraReducers: (builder) => {
    builder
      // Register
      .addCase(getInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getInfo.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.topicStatus = state.data.topicStatus
        if (!state.data.topicStatus) state.currentStatus = 'offline'
        state.isSuccess = true
        state.isLoading = false
      })
      .addCase(getInfo.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.errorMessage = action.payload
      })
  },
})

export const { changeStatus } = InfoSlice.actions
export default InfoSlice.reducer
