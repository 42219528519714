import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import Avatar from '../assets/images/defaultAvatar.jpg'
import { changeStatus, getInfo } from '../redux/infoSlice'

const UserInfo = () => {
  const dispatch = useDispatch()
  const { data, currentStatus } = useSelector((state) => state.info)
  const { isAuth } = useSelector((state) => state.auth)

  useEffect(() => {
    // dispatch(changeStatus())
  }, [])

  return (
    data && (
      <div className="user-info">
        <div className="avatar">
          <img src={Avatar} />
          {isAuth && <span className={currentStatus}></span>}
        </div>
        <div>
          <h3>{data.name}</h3>
          <h4>{data.topicName}</h4>
        </div>
      </div>
    )
  )
}

export default UserInfo
