import { GoogleOAuthProvider } from '@react-oauth/google'
import { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Client from './views/ClientWidget'

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<h2>Link: domain/topicID</h2>} />
          <Route
            path=":topicId"
            element={
              <GoogleOAuthProvider clientId="152285902226-jjb7ngjf93p4plbibco3vnmu9bdb1pbi.apps.googleusercontent.com">
                <Client />
              </GoogleOAuthProvider>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
