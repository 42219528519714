import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
  socketEmit: '',
  minutesAlertModal: false,
  paymentModal: false,
  paymentModalText: '',
  sureModal: false,
  newCardModal: false,
  waitModal: false,
  deleteCardId: null,
  amount: 0,
  isNewCard: false,
  cardId: null,
  cardNumber: null,
  expMonth: null,
  expYear: null,
  cvc: null,
}

export const paymentSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    resetPayment: (state, action) => {
      return { ...initialState }
    },

    openPaymentModal: (state, action) => {
      state.paymentModal = true
      state.paymentModalText = 'Top up your credits'
    },
    openPaymentModalInCall: (state, action) => {
      state.paymentModal = true
      state.socketEmit = action.payload
    },
    openAddNewCard: (state, action) => {
      state.paymentModal = false
      state.newCardModal = true
    },
    openDeleteCard: (state, action) => {
      state.deleteCardId = action.payload
      state.paymentModal = false
      state.sureModal = true
    },
    backOnPaymentModal: (state, action) => {
      state.paymentModal = true
      state.sureModal = false
      state.newCardModal = false
      state.deleteCardId = null
    },
    openWaitModal: (state, action) => {
      state.paymentModal = false
      state.newCardModal = false
      state.waitModal = true
    },
    setAmountHandler: (state, action) => {
      state.amount = action.payload
    },
    setCardIdHandler: (state, action) => {
      state.cardId = action.payload
    },
    setNewCardPayment: (state, action) => {
      state.cardNumber = action.payload.cardNumber
      state.cvc = action.payload.cvc
      state.expYear = action.payload.expYear
      state.expMonth = action.payload.expMonth
      state.isNewCard = action.payload.isNewCard
      state.waitModal = true
      state.newCardModal = false
    },
  },
})

export const {
  resetPayment,
  openPaymentModal,
  openAddNewCard,
  openDeleteCard,
  backOnPaymentModal,
  setAmountHandler,
  setCardIdHandler,
  openWaitModal,
  setNewCardPayment,
  openPaymentModalInCall,
} = paymentSlice.actions
export default paymentSlice.reducer
