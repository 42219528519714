import React from 'react'
import Close from '../assets/images/close.svg'

import '../assets/styles/modal.scss'

const Modal = ({ children, onClose, disableClose, hide }) => {
  return (
    <div className={`widget-modal ${hide ? 'hide' : ''}`}>
      <div className="widget-modal__box">
        {!disableClose && (
          <button onClick={onClose} className="widget-modal__box-close">
            <img src={Close} />
          </button>
        )}
        <div className="widget-modal__content">{children}</div>
      </div>
    </div>
  )
}

export default Modal
