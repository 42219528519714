import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { changeStep, updatePackages } from '../../redux/registerSlice'

const packages = [
  {
    id: 1,
    price: 10,
    min: 5,
  },
  {
    id: 2,
    price: 15,
    min: 10,
  },
  {
    id: 3,
    price: 30,
    min: 15,
  },
  {
    id: 4,
    price: 50,
    min: 30,
  },
  {
    id: 5,
    price: 50,
    min: 30,
  },
  {
    id: 6,
    price: 75,
    min: 45,
  },
]

const PackagesStep = () => {
  const dispatch = useDispatch()

  const onStepChange = (pack) => {
    dispatch(updatePackages(pack))
    dispatch(changeStep('card'))
  }

  return (
    <>
      <div className="payment__info">
        <h2>Pick a package</h2>
        <p>
          To enter a call with advisor pick one package. Don’t worry, you can
          always add more minutes to your call
        </p>
      </div>

      <ul className="packages">
        {packages.map((pack, i) => (
          <li key={i}>
            <button
              onClick={() => onStepChange(pack)}
            >{`${pack.min} minutes - ${pack.price} €`}</button>
          </li>
        ))}
      </ul>
    </>
  )
}

export default PackagesStep
