import React, { useEffect, useState } from 'react'

import '../../assets/styles/payment.scss'

import PackagesStep from './PackagesStep'
import CardStep from './CardStep'
import RegisterStep from './RegisterStep'
import PayingStep from './PayingStep'
import FailStep from './FailStep'
import ExistingStep from './ExistingStep'
import { useSelector } from 'react-redux'
import { selectRegister } from '../../redux/registerSlice'
import { selectLayouts } from '../../redux/layoutSlice'

const Index = () => {
  const { step } = useSelector(selectRegister)
  const { openPayment } = useSelector(selectLayouts)

  return (
    <div className={`payment ${!openPayment ? 'closed' : ''}`}>
      {step === 'packages' && <PackagesStep />}
      {step === 'card' && <CardStep />}
      {step === 'register' && <RegisterStep />}
      {step === 'paying' && <PayingStep />}
      {step === 'fail' && <FailStep />}
      {step === 'existing' && <ExistingStep />}
    </div>
  )
}

export default Index
