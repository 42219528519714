import React, { useEffect, useState } from 'react'
import { MessageSquare, PhoneCall, Video } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { addMessage, removeLastMessage } from '../../redux/chatSlice'
import plusCircle from '../../assets/images/plus-circle.svg'
import { selectLayouts, toggleChatVideo } from '../../redux/layoutSlice'
import { useSocket } from '../../utility/context/socketContext'

import UserInfo from '../UserInfo'
import Chat from './AuthChat'

import { reset as resetRegister } from '../../redux/registerSlice'
import { reset as resetAuth, updateCredits } from '../../redux/authSlice'

import { onOutcomingCall } from '../../redux/callSlice'
import {
  openPaymentModal,
  openPaymentModalInCall,
} from '../../redux/paymentSlice'
import { changeStatus } from '../../redux/infoSlice'

const AuthContent = () => {
  const socket = useSocket()
  const dispatch = useDispatch()
  const { isSuccess, isRegister, client } = useSelector((state) => state.auth)
  const { id: chatId } = useSelector((state) => state.chat)

  const { chatVideo } = useSelector(selectLayouts)

  useEffect(() => {
    if (isSuccess) {
      dispatch(resetRegister())
      dispatch(resetAuth())
    }
  }, [isSuccess, dispatch])

  // Conversation
  const [coversationStart, onConversationStart] = useState(false)
  const [timeLeft, setTimeLeft] = useState(0)
  const [isCountdownActive, setIsCountdownActive] = useState(false)

  useEffect(() => {
    if (isCountdownActive) {
      const timer = setInterval(() => {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1)
      }, 1000)

      return () => {
        clearInterval(timer)
      }
    }
  }, [isCountdownActive])

  useEffect(() => {
    if (timeLeft === 60) {
      dispatch(openPaymentModalInCall('client_micro_conversation_payment'))
    }
  }, [timeLeft])

  const startCountdown = (seconds) => {
    setTimeLeft(seconds)
    setIsCountdownActive(true)
  }

  const startConversation = (seconds) => {
    onConversationStart(true)
    startCountdown(seconds)
  }

  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600)
    const minutes = Math.floor((totalSeconds % 3600) / 60)
    const seconds = totalSeconds % 60

    const formattedHours = hours.toString().padStart(2, '0')
    const formattedMinutes = minutes.toString().padStart(2, '0')
    const formattedSeconds = seconds.toString().padStart(2, '0')

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
  }

  const handleEndConversation = () => {
    socket.emit('endConversation', chatId)
    onConversationStart(false)
    setIsCountdownActive(false)
  }

  // Socket
  useEffect(() => {
    socket.on('message', ({ messageObject }) => {
      dispatch(addMessage(messageObject))
    })

    socket.on('micro_message_handler', ({ payload }) => {
      console.log(payload)
      switch (payload) {
        case 'chat_pending':
          dispatch(
            removeLastMessage({
              content:
                'Your chat is currently awaiting approval and is in a pending state.',
              sender: 'me',
            })
          )
          break
        case 'chat_no_credits':
          dispatch(
            removeLastMessage({
              content: `Your chat is currently in a 'no credits' state.`,
              sender: 'me',
            })
          )
          break
      }
    })

    socket.on('startConversation', (timeLeft) => {
      startConversation(timeLeft)
    })

    socket.on('updateConversationTime', (timeLeft) => {
      setTimeLeft(timeLeft)
    })

    // Call handler
    socket.on('micro_call_handler', ({ payload, type }) => {
      switch (payload) {
        case 'call_request':
          dispatch(onOutcomingCall(type))
          break
        case 'call_no_credits':
          console.log('call_no_credits')
          window.alert('call_no_credits')
          break
        case 'advisor_busy':
          console.log('advisor_busy')
          window.alert('advisor_busy')
          break
      }
    })

    socket.on('micro_client_end_conversation', () => {
      onConversationStart(false)
      setIsCountdownActive(false)
    })

    socket.on('micro_update_credits', (credits) => {
      dispatch(updateCredits(credits))
    })

    socket.on('change_advisor_status', ({ status }) => {
      console.log('change_advisor_status')
      dispatch(changeStatus({ status }))
    })

    return () => {
      socket.off('message')
      socket.off('change_advisor_status')
      socket.off('startConversation')
      socket.off('micro_call_handler')
      socket.off('micro_client_end_conversation')
      socket.off('micro_update_credits')
      socket.off('change_advisor_status')
    }
  }, [])

  // Channels
  const { channels: displayRules } = useSelector((state) => state.info.data)

  // Handle call request
  // const callRequestHandler = async (callType) => {
  //   const audioPermission = await navigator.permissions.query({
  //     name: 'microphone',
  //   })
  //   const videoPermission = await navigator.permissions.query({
  //     name: 'camera',
  //   })

  //   console.log('audiooooooooooooooooooo')
  //   console.log(audioPermission)
  //   console.log('----------------------')

  //   console.log('videoooooooooooooooooooo')
  //   console.log(videoPermission)
  //   console.log('----------------------')

  //   if (
  //     audioPermission.state === 'granted' &&
  //     videoPermission.state === 'granted'
  //   ) {
  //     alert('Microphone and Camera access granted!')
  //   } else {
  //     alert('Microphone or Camera access denied or not requested yet.')
  //   }

  //   return
  //   socket.emit('call', { type: callType, chatId })
  // }

  const audioRequestHandler = async () => {
    const audioPermission = await navigator.permissions.query({
      name: 'microphone',
    })

    if (audioPermission.state === 'granted') {
      socket.emit('call', { type: 'audio', chatId })
    } else if (audioPermission.state === 'prompt') {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        })
        stream.getTracks().forEach((track) => track.stop())
        socket.emit('call', { type: 'audio', chatId })
      } catch (error) {
        alert('Microphone access denied or an error occurred.')
      }
    } else {
      alert('Microphone access denied or not requested yet.')
    }
  }
  const videoRequestHandler = async () => {
    const audioPermission = await navigator.permissions.query({
      name: 'microphone',
    })
    const videoPermission = await navigator.permissions.query({
      name: 'camera',
    })

    console.log(videoPermission)

    if (
      audioPermission.state === 'granted' &&
      videoPermission.state === 'granted'
    ) {
      socket.emit('call', { type: 'video', chatId })
    } else if (
      audioPermission.state === 'prompt' ||
      videoPermission.state === 'prompt'
    ) {
      try {
        const constraints = { audio: true, video: true }
        const stream = await navigator.mediaDevices.getUserMedia(constraints)
        stream.getTracks().forEach((track) => track.stop())

        socket.emit('call', { type: 'video', chatId })
      } catch (error) {
        alert('Microphone and/or Camera access denied or an error occurred.')
      }
    } else {
      alert('Microphone and/or Camera access denied or not requested yet.')
    }
  }

  return (
    <main>
      <UserInfo />
      {/* Main */}
      {coversationStart ? <Chat /> : chatVideo ? <Chat /> : null}

      {!chatVideo && (
        <div className="talk">
          <h2>Let’s Talk</h2>

          {displayRules.includes('audio') && (
            <button
              className=" c-button video"
              onClick={() => audioRequestHandler()}
            >
              <PhoneCall />
              Start Audio Call
            </button>
          )}
          {displayRules.includes('video') && (
            <button
              className=" c-button audio"
              onClick={() => videoRequestHandler()}
            >
              <Video />
              Start Video Call
            </button>
          )}

          {displayRules.length === 1 && displayRules[0] === 'chat' && (
            <button
              className="c-button audio"
              onClick={() => {
                dispatch(toggleChatVideo(true))
              }}
            >
              <MessageSquare size={18} />
              Start Conversation
            </button>
          )}

          <div className="founds">
            <span>Founds left:</span>
            <button onClick={() => dispatch(openPaymentModal())}>
              <img src={plusCircle} />
              {client.credits} €
            </button>
          </div>
        </div>
      )}

      {coversationStart && (
        <div className="timer-chat">
          <p>
            Time left:
            <span>{formatTime(timeLeft)}</span>
          </p>

          <button onClick={handleEndConversation} className="end">
            End chat
          </button>
        </div>
      )}

      {chatVideo &&
        displayRules.includes('chat') &&
        displayRules.length === 1 &&
        !coversationStart && (
          <div className="footer">
            <button
              style={{ width: '100%', borderRadius: '0' }}
              onClick={() => {
                dispatch(toggleChatVideo(false))
              }}
            >
              Back
            </button>
          </div>
        )}

      {/* Nav footer */}
      {displayRules.includes('chat') &&
        displayRules.length > 1 &&
        !coversationStart && (
          <div className="footer">
            <button
              className={!chatVideo ? 'active' : ''}
              onClick={() => dispatch(toggleChatVideo(false))}
            >
              <Video size={18} />
            </button>
            <button
              className={chatVideo ? 'active' : ''}
              onClick={() => dispatch(toggleChatVideo(true))}
            >
              <MessageSquare size={18} />
            </button>
          </div>
        )}
    </main>
  )
}

export default AuthContent
