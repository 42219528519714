import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const API_URL = process.env.REACT_APP_SERVER_PORT + '/api'

// Login user
export const loginClient = createAsyncThunk(
  'auth/login',
  async (req, thunkAPI) => {
    try {
      const response = await axios.post(API_URL + '/auth/client/login', req)

      if (response.data) {
        localStorage.setItem('client', JSON.stringify(response.data.data))
      }

      return response.data
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const registerClient = createAsyncThunk(
  'auth/register',
  async (req, thunkAPI) => {
    try {
      const response = await axios.post(API_URL + '/auth/client/register', req)

      if (response.data) {
        localStorage.setItem('client', JSON.stringify(response.data.data))
      }

      return response.data
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Logout
export const logoutClient = createAsyncThunk('auth/logout', async () => {
  await localStorage.removeItem('client')
})

const client = JSON.parse(localStorage.getItem('client'))

const initialState = {
  isAuth: client ? true : false,
  client: client ? client : null,
  isRegister: false,
  isSuccess: false,
  isLoading: false,
  isError: false,
  error: '',
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state, action) => {
      state.isSuccess = false
      state.isLoading = false
      state.isError = false
      state.error = ''
      state.isRegister = false
    },
    updateCredits: (state, action) => {
      state.client = {
        ...state.client,
        credits: action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Register
      .addCase(registerClient.pending, (state) => {
        state.isLoading = true
      })
      .addCase(registerClient.fulfilled, (state, action) => {
        state.isAuth = true
        state.client = action.payload.data
        state.isSuccess = true
        state.isLoading = false
        state.isRegister = true
      })
      .addCase(registerClient.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.error = action.payload
      })
      // Login
      .addCase(loginClient.pending, (state) => {
        state.isLoading = true
      })
      .addCase(loginClient.fulfilled, (state, action) => {
        state.isAuth = true
        state.client = action.payload.data
        state.isSuccess = true
        state.isLoading = false
      })
      .addCase(loginClient.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.error = action.payload
      })
      // Logout
      .addCase(logoutClient.fulfilled, (state) => {
        state.isAuth = false
        state.client = null
        state.isLoading = false
        state.isError = false
        state.error = ''
      })
  },
})

export const { reset, updateCredits } = authSlice.actions
export default authSlice.reducer
