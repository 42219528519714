import React from 'react'
import LockRed from '../../assets/images/lock-red.png'
import { logoutClient } from '../../redux/authSlice'
import { selectLayouts, toggleLogout } from '../../redux/layoutSlice'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../Modal'

const PasswordResetModal = () => {
  const dispatch = useDispatch()

  // const { openPasswordReset } = useSelector(selectLayouts)

  const openPasswordReset = false

  return (
    <Modal
      hide={!openPasswordReset}
      disableClose
      onClose={() => {
        console.log('close')
      }}
    >
      <div className="simple-modal">
        <div className="simple-modal__content">
          <img src={LockRed} alt="LockRed" />
          <h3>Password reset?</h3>
          <p>
            If you have forgotten your password, don't worry! You can easily
            reset it and we'll send instructions to your email.
          </p>
        </div>

        <div className="btns">
          <button onClick={() => {}} className="btn-primary">
            Cancel
          </button>
          <button className="btn-secondary">Reset password</button>
        </div>
      </div>
    </Modal>
  )
}

export default PasswordResetModal
